import ApiRequest from "../index";
import type { R } from "../request/type";
//用户名密码登录
export function upLogin(
  username: string,
  password: string,
  code: string,
  flag: string
) {
  return ApiRequest.post<R>({
    url: "/devplus/login",
    data: { username, password, code, flag },
  });
}
//手机号密码登录
export function smsLogin(phone: string, smsCode: string) {
  return ApiRequest.post<R>({
    url: "/devplus/smslogin",
    data: { phone, smsCode },
  });
}
//企业微信登录
export function wxWorkLogin(code: string) {
  return ApiRequest.post<R>({
    url: "/devplus/wxworklogin",
    data: { code },
  });
}
//用户名密码+手机号验证码登录
export function smsPwdLogin(
  username: string,
  password: string,
  mobile: string,
  smsCode: string
) {
  return ApiRequest.post<R>({
    url: "/devplus/smspwdlogin",
    data: { username, password, mobile, smsCode },
  });
}
//获取验证码
export function getsmsvcode(username: string, mobile: string, smsType: string) {
  return ApiRequest.post<R>({
    url: "/devplus/openapi/public/sys/sms/sendsmsCode",
    data: { mobile, username, smsType },
  });
}
//退出登录
export function loginOut() {
  return ApiRequest.post<R>({
    url: "/devplus/loginout",
  });
}
//获取当前用户信息
export function getUserInfo() {
  return ApiRequest.get<R>({
    url: "/devplus/sys/home/getUserInfo",
  });
}
//获取当前用户菜单
export function getMenuList() {
  return ApiRequest.get<R>({
    url: "/devplus/sys/home/getMenuList",
  });
}
//获取当前用户信息
export function changePassword(oldPassword: string, newPassword: string) {
  return ApiRequest.post<R>({
    url: "/devplus/sys/user/changePassword",
    data: { oldPassword, newPassword },
  });
}
//测试登录
export function testLogin(username: string, password: string, code: string) {
  return ApiRequest.post<R>({
    url: "/devplus/openapi/public/sys/code/getmdCode",
    data: { username, password, code },
  });
}
//忘记密码
//获取当前用户信息
export function forgetPassword(account: object) {
  return ApiRequest.post<R>({
    url: "/devplus/openapi/public/sysUser/changePasswod",
    data: account,
  });
}
