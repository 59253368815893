import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/microapp/:pathMatch(.*)*",
    name: "MicroApp",
    component: () => import("@/layout/admin.vue"),
  },
  {
    path: "/",
    redirect: "/main/console",
  },

  {
    path: "/account",
    name: "Account",
    component: () => import("@/layout/account.vue"),
    children: [
      {
        path: "uplogin",
        name: "Login",
        component: () => import("@/views/account/login.vue"),
      },
      {
        path: "forgetPwd",
        name: "forgetPwd",
        component: () => import("@/views/account/forget-pwd.vue"),
      },
      // {
      //   path: "uplogin",
      //   name: "UpLogin",
      //   component: () => import("@/views/account/uplogin.vue"),
      // },
      // {
      //   path: "smslogin",
      //   name: "SmsLogin",
      //   component: () => import("@/views/account/smslogin.vue"),
      // },
      {
        path: "wxworklogin",
        name: "WxWorkLogin",
        component: () => import("@/views/account/wxworklogin.vue"),
      },
    ],
  },
  {
    path: "/act",
    name: "ActPage",
    component: () => import("@/views/account/activation.vue"),
  },
  {
    path: "/main",
    name: "Admin",
    component: () => import("@/layout/admin.vue"),
    meta: { title: "系统管理" },
    children: [
      {
        path: "console",
        name: "ConsoleIndex",
        component: () => import("@/views/console/index.vue"),
        meta: { title: "控制台" },
      },
      {
        path: "system/orgtype",
        name: "OrgType",
        component: () => import("@/views/sys/orgtype/index.vue"),
        meta: { title: "机构类型" },
      },
      {
        path: "system/orglevel",
        name: "OrgLevel",
        component: () => import("@/views/sys/orglevel/index.vue"),
        meta: { title: "机构级别" },
      },
      {
        path: "system/duty",
        name: "Duty",
        component: () => import("@/views/sys/duty/index.vue"),
        meta: { title: "职务管理" },
      },
      {
        path: "system/microapp",
        name: "Microapp",
        component: () => import("@/views/sys/microapp/index.vue"),
        meta: { title: "微应用管理" },
      },
      {
        path: "system/res",
        name: "Res",
        component: () => import("@/views/sys/res/index.vue"),
        meta: { title: "资源管理" },
      },
      {
        path: "system/region",
        name: "Region",
        component: () => import("@/views/sys/region/index.vue"),
        meta: { title: "行政区划" },
      },
      {
        path: "system/role",
        name: "Role",
        component: () => import("@/views/sys/role/index.vue"),
        meta: { title: "角色管理" },
      },
      {
        path: "system/org",
        name: "Org",
        component: () => import("@/views/sys/org/index.vue"),
        meta: { title: "机构管理" },
      },
      {
        path: "system/user",
        name: "User",
        component: () => import("@/views/sys/user/index.vue"),
        meta: { title: "用户管理" },
      },
      {
        path: "system/log/loginLog",
        name: "loginLogList",
        component: () => import("@/views/sys/log/loginlog/index.vue"),
        meta: { title: "登录日志" },
      },
      {
        path: "system/log/operLog",
        name: "operLogList",
        component: () => import("@/views/sys/log/operlog/index.vue"),
        meta: { title: "操作日志" },
      },
      {
        path: "system/log/smsLog",
        name: "smsLogList",
        component: () => import("@/views/sys/log/smslog/index.vue"),
        meta: { title: "短信发送记录" },
      },
      {
        path: "system/config",
        name: "Config",
        component: () => import("@/views/sys/config/index.vue"),
        meta: { title: "系统配置" },
      },
      {
        path: "system/dict/type",
        name: "dictTypeList",
        component: () => import("@/views/sys/dict/dictType/index.vue"),
        meta: { title: "字典管理" },
      },
      {
        path: "system/dict/data/:dictId",
        name: "dictDataList",
        component: () => import("@/views/sys/dict/dictData/index.vue"),
        meta: { title: "字典数据" },
      },
    ],
  },

  {
    path: "/test",
    name: "testIndex",
    component: () => import("@/views/test/index.vue"),
  },
  // {
  //   path: "/:pathMatch(.*)*",
  //   name: "NoFound",
  //   component: () => import("@/views/error/NoFound.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
