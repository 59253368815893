import { defineComponent } from "vue";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default defineComponent({
  setup() {
    return {
      zIndex: 3000,
      size: "default",
      locale: zhCn
    };
  }

});