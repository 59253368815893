import axios from "axios";
import type { AxiosInstance } from "axios";
import type { ApiRequestInterceptors, ApiRequestConfig } from "./type";
import { ElLoading } from "element-plus";
import { LoadingInstance } from "element-plus/es/components/loading/src/loading";
import "element-plus/theme-chalk/el-loading.css";

const DEFAULT_LADING = true;
class ApiRequest {
  instance: AxiosInstance;
  interceptorys?: ApiRequestInterceptors;
  showLoading: boolean;
  loading?: LoadingInstance;
  constructor(config: ApiRequestConfig) {
    this.instance = axios.create(config);
    console.info(config.showLoading);
    if (config.showLoading == undefined) {
      this.showLoading = DEFAULT_LADING;
      console.info("未定义，取默认值" + this.showLoading);
    } else {
      this.showLoading = config.showLoading;
      console.info("已定义，取配置值" + this.showLoading);
    }

    //添加所有的实例都有的拦截器
    this.instance.interceptors.request.use(
      (config) => {
        if (this.showLoading === true) {
          this.loading = ElLoading.service({
            lock: true,
            text: "正在请求数据...",
            background: "rgba(0, 0, 0, 0.5)",
            spinner: "el-icon-loading",
          });
        }
        return config;
      },
      (err) => {
        return err;
      }
    );
    this.interceptorys = config.interceptors;
    // 从config中取出的拦截器是对应的实例的拦截器
    this.instance.interceptors.request.use(
      this.interceptorys?.requestInterceptor,
      this.interceptorys?.requestInterceptorCatch
    );
    this.instance.interceptors.response.use(
      this.interceptorys?.responseInterceptor,
      this.interceptorys?.responseInterceptorCatch
    );

    this.instance.interceptors.response.use(
      (res) => {
        this.loading?.close();
        return res;
      },
      (err) => {
        this.loading?.close();
        return err;
      }
    );
  }
  request<T = any>(config: ApiRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      // 单个请求对config的处理
      if (config.interceptors?.requestInterceptor) {
        config = config.interceptors.requestInterceptor(config);
      }
      //判断是否显示loading
      if (config.showLoading) {
        this.showLoading = config.showLoading;
      }
      this.instance
        .request<any, T>(config)
        .then((res) => {
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res);
          }
          this.showLoading = DEFAULT_LADING;
          resolve(res);
          return res;
        })
        .catch((err) => {
          this.showLoading = DEFAULT_LADING;
          reject(err);
          return err;
        });
    });
  }
  get<T = any>(config: ApiRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "GET" });
  }
  post<T = any>(config: ApiRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "POST" });
  }
  delete<T = any>(config: ApiRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "DELETE" });
  }
  PATCH<T = any>(config: ApiRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "PATCH" });
  }
  put<T = any>(config: ApiRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "PUT" });
  }
}
export default ApiRequest;
