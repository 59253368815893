import { createStore } from "vuex";
import sys from "./modules/sys";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    sys,
  },
});
