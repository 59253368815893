// 引入路由和storage工具函数
import stroage from "@/util/stroage";
import router from "@/router";

let lastTime = new Date().getTime();
let currentTime = new Date().getTime();
const timeOut = 60 * 60 * 1000; //设置超时时间: 60分钟

window.onload = function () {
  window.document.onmousedown = function () {
    stroage.setItem("lastTime", new Date().getTime());
  };
};

function checkTimeout() {
  currentTime = new Date().getTime(); //更新当前时间
  lastTime = stroage.getItem("lastTime", "1");

  if (currentTime - lastTime > timeOut) {
    //判断是否超时

    // 清除storage的数据(token)
    //stroage.clear();
    // 跳到登陆页
    console.info(router);
    if (router.currentRoute.value.name == "Login") return; // 当前已经是登陆页时不做跳转
    router.push({ name: "Login" });
  }
}

export default function () {
  /* 定时器 间隔30秒检测是否长时间未操作页面 */
  window.setInterval(checkTimeout, 30000);
}
