import qs from "qs";
import ApiRequest from "./request/request";
import { ApiRequestConfig } from "./request/type";
import { BASE_URL, TIME_OUT } from "./request/config";
import sessionCache from "@/util/sessioncache";
import router from "@/router/index";
//import store from "@/store/index";
import { ElMessage } from "element-plus";

console.info("BASE_URL:" + BASE_URL);
const apiRequest = new ApiRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config: ApiRequestConfig) => {
      const token = sessionCache.getCache("token");
      if (token) {
        if (config.headers) {
          config.headers.token = token;
        }
      }
      config.showLoading = false;
      //config.data = qs.stringify(config.data);
      if (config.headers) {
        //config.headers["content-type"] = "application/x-www-form-urlencoded";
        config.headers["content-type"] = "application/json";
      }

      return config;
    },
    requestInterceptorCatch: (err) => {
      return err;
    },
    responseInterceptor: (res) => {
      if (res.data.code === 401) {
        //ElMessage("您尚未登录,请先登录");
        sessionCache.setCache("redirect_url", window.location.href);
        router.push("/account/uplogin");
      }
      return res.data;
    },
  },
});
export default apiRequest;
