export default {
  setItem(key: any, value: any) {
    value = JSON.stringify(value);
    window.localStorage.setItem(key, value);
  },
  getItem(key: any, defaultValue: any) {
    let value = window.localStorage.getItem(key);
    try {
      value = JSON.parse(value ? value : "");
    } catch {
      console.info();
    }
    return value || defaultValue;
  },
  removeItem(key: any) {
    window.localStorage.removeItem(key);
  },
  clear() {
    //console.info(window.localStorage.clear);
    //window.localStorage.clear();
  },
};
