import { getUserInfo, getMenuList } from "@/api/account/accountapi";
export default {
  namespaced: true,
  state: {
    token: "",
    userInfo: {
      username: "",
      mobile: "",
      user_id: "",
    },
    userMenus: [],
  },
  getters: {
    getToken(state: any) {
      return state.token;
    },
    getUserInfo(state: any) {
      return state.userInfo;
    },
    getUserMenus(state: any) {
      return state.userMenus;
    },
  },
  mutations: {
    setToken(state: any, payload: any) {
      state.token = payload;
    },
    setUserInfo(state: any, payload: any) {
      state.userInfo = payload;
    },
    setUserMenus(state: any, payload: any) {
      state.userMenus = payload;
    },
  },
  actions: {
    //请求远程接口，载入用户信息
    async loadUserInfo(context: any) {
      console.info("正在调用loadUserInfo");
      getUserInfo().then((res) => {
        if (res.code === 0) {
          context.commit("setUserInfo", res.data);
        }
        console.info("调用loadUserInfo成功");
      });
    },
    //请求远程接口，载入用户菜单
    async loadUserMenus(context: any) {
      console.info("正在调用loadUserMenus");
      getMenuList().then((res) => {
        if (res.code === 0) {
          context.commit("setUserMenus", res.data);
        }
        console.info("调用setUserMenus成功");
      });
    },
  },
};
